$(function() {

    // Reviews
    $('.rateYo').each(function() {
        $(this).rateYo({
            starWidth: "15px",
            rating: this.getAttribute('rate'),
            //halfStar: true,
            readOnly: true
        });
    });

    //Datepicker
    $('#dpMonths').fdatepicker({
        format: 'mm-dd-yyyy hh:ii',
        disableDblClickSelection: true,
        pickTime: true
    });
});

function emailError(){
    alert("Veuillez entrer un email valide!");
}

function validateEmail(sEmail) {
    var filter = /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/;
    
    if ((filter.test(sEmail))&&($.trim(sEmail).length != 0)) {
        return true;
    }
    else {
        return false;
    }
}

$( document ).ready(function() {
   $("#subscribe-success").hide();
   $("#ambassador-success").hide();
   $("#subscribe").click(function(){
      var myemail = $( "input[name='subscribe-email']" ).val();
      if(validateEmail(myemail)){
          $.post( "https://varibase.emsecure.net/optiext/optiextension.dll?ID=Ji6fmdEEeckWxOb08ha59m5tPGBw6WdxAMYRPR0fX5rYh4FhewAjcHE%2B53aHKBCRvd_Vti09jRzsJy", { EMAIL: myemail })
          .done(function( data ) {
            $("#subscribe-form").hide();
            $("#subscribe-success").fadeIn("slow");
          });
      }
      else {
          emailError();
      } 
   });
    $("#submit-ambassador").click(function(){
      var myemail = $( "input[name='email']" ).val();
      var mylastname = $( "input[name='last_name']" ).val();
      var myfirstname = $( "input[name='first_name']" ).val();
      var myphone = $( "input[name='phone']" ).val();
      var mypostal = $( "input[name='postal']" ).val();
      if(validateEmail(myemail)){
          $.post( "https://varibase.emsecure.net/optiext/optiextension.dll?ID=dStOqIMnRfaFcHbLkRXU_MeIPvumSlNzoobkRd2F8DkGY6wg9dtLPIvO0PPJ3kcBk6k%2BWNO8GwoXdL", { EMAIL: myemail, FIRST_NAME: myfirstname, LAST_NAME: mylastname, PHONE: myphone, POSTAL: mypostal })
          .done(function( data ) {
            $("#ambassador-form").hide();
            $("#ambassador-success").fadeIn("slow");
          });
      }
      else {
          emailError();
      } 
   });
    
 
    
    $(window).resize(function(){
        if($(window).width() > 640){
            $("#login").backstretch("destroy");
            $("#login").backstretch([
              "assets/img/step1.png",
              "assets/img/step2.png",
              "assets/img/step3.png",
              "assets/img/step4.png",
              "assets/img/step5.png"
              ], {
                fade: 750,
                duration: 3000,
                animateFirst: false,
                preload:1
            });
        }else{
            $("#login").backstretch("destroy");
            $("#login").backstretch([
              "assets/img/step1.m.jpg",
              "assets/img/step2.m.jpg",
              "assets/img/step3.m.jpg",
              "assets/img/step4.m.jpg"
              ], {
                fade: 750,
                duration: 3000,
                animateFirst: false,
                preload:1
            });
        }
    }).resize();
    

});